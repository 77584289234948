.badge {
	background-color: var(--color-background);
	border: var(--space-1--px) solid var(--color-foreground);
	border-radius: 4px;
	color: var(--color-foreground);
	display: inline-block;
	padding: 3px var(--space-12--px);
	text-decoration: none;
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
}

.badge:not(:only-child) {
	margin-right: var(--space-8--px);
	margin-bottom: var(--space-8--px);
}

a.badge:hover,
a.badge:focus {
	background-color: var(--color-greyish-brown);
	border-color: var(--color-greyish-brown);
	color: var(--color-background);
}
