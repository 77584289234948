.navigation-overlay {
	background-color: var(--color-background);
	position: relative;
	height: 100%;
	min-height: 100vh;
}

.navigation-overlay__header {
	box-sizing: border-box;
	display: flex;
	padding: var(--space-20--px) var(--space-10--px) var(--space-32--px);
	justify-content: space-between;
	min-height: 4.25rem;
}

.navigation-overlay__body {
	padding: var(--space-16--px) var(--space-10--px);
}

/* #region Language switch */
.navigation-overlay__language-switch {
	appearance: none;
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	fill: var(--color-foreground);
	padding: 0;
}

.navigation-overlay__language-switch .icon {
	display: block;
	width: var(--space-16--rem);
	height: var(--space-16--rem);
}

.navigation-overlay__language-switch .icon-language-globe {
	margin-right: 6px;
}

.navigation-overlay__language-switch[aria-expanded="true"] .icon-language-globe {
	fill: rgb(85 84 79);
}

@supports (color: var(--color-foreground)) {
	.navigation-overlay__language-switch[aria-expanded="true"] .icon-language-globe {
		fill: var(--color-greyish-brown);
	}
}

.navigation-overlay__language-switch .icon-chevron-down {
	transform: scale(1, 1);
	transition: transform 144ms ease-in-out;
}

.navigation-overlay__language-switch[aria-expanded="true"] .icon-chevron-down {
	fill: rgb(85 84 79);
	transform: scale(1, -1);
}

@supports (color: var(--color-foreground)) {
	.navigation-overlay__language-switch[aria-expanded="true"] .icon-chevron-down {
		fill: var(--color-greyish-brown);
	}
}

.navigation-overlay__language-select {
	visibility: hidden;
	height: 0;
}

.navigation-overlay__language-select.mr-menu--expanded,
.navigation-overlay__language-select--no-js {
	visibility: visible;
	height: auto;
}

.navigation-overlay__language-select__list {
	border-top: var(--space-1--px) solid var(--color-greyish-brown);
	border-bottom: var(--space-1--px) solid var(--color-greyish-brown);
	list-style: none;
	margin: 0;
	opacity: 0;
	padding: var(--space-16--px) var(--space-10--px);
	transform: translate3d(0, calc(var(--space-16--px) * -1), 0);
	transition:
		opacity 96ms ease-out,
		transform 128ms ease-out;
}

.navigation-overlay__language-select__list--no-js {
	border-top: var(--space-1--px) solid var(--color-greyish-brown);
	border-bottom: var(--space-1--px) solid var(--color-greyish-brown);
	list-style: none;
	margin: 0;
	padding: var(--space-16--px) var(--space-10--px);
}

.navigation-overlay__language-select.mr-menu--expanded .navigation-overlay__language-select__list {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition:
		opacity 400ms ease-out,
		transform 256ms ease-out;
}

.navigation-overlay__language-select__list__item:not(:last-child) {
	margin-bottom: var(--space-16--px);
}

.navigation-overlay__language-select__link {
	text-decoration: none;
}

.navigation-overlay__language-select__list__item--active .navigation-overlay__language-select__link {
	color: var(--color-foreground);
}
/* #endregion Language switch */

/* #region Menu List */
.navigation-overlay__menu-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-overlay__menu-list__item {
	margin-bottom: var(--space-8--px);
}

.navigation-overlay__menu-list__link {
	color: var(--color-foreground);
	text-decoration: none;
}

.navigation-overlay__menu-list__item .navigation-overlay__menu-list__link:hover,
.navigation-overlay__menu-list__item--current .navigation-overlay__menu-list__link {
	color: var(--color-greyish-brown);
	text-decoration: underline 0.0625rem;
}
/* #endregion Menu List */

/* #region Close Button */
.navigation-overlay__close-wrapper {
	position: absolute;
	top: var(--space-20--px);
	right: var(--space-10--px);
}

.navigation-overlay__close-button {
	appearance: none;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

.navigation-overlay__close-button .icon {
	display: block;
	fill: rgb(0 0 0);
	width: 1.375rem;
	height: 1.375rem;
}

@supports (color: var(--color-foreground)) {
	.navigation-overlay__close-button .icon {
		fill: var(--color-foreground);
	}
}
/* #endregion Close Button */
