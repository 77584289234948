.pagination {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.pagination__item {
	box-sizing: border-box;
	display: flex;
	list-style-type: none;
	justify-content: center;
	align-items: center;
	width: calc((40px + 40px + 2.5rem) / 3);
	height: calc((40px + 40px + 2.5rem) / 3);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.pagination__item {
		width: calc((44px + 44px + 2.75rem) / 3);
		height: calc((44px + 44px + 2.75rem) / 3);
	}
}

.pagination__item a {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition: color 96ms linear;
	width: 100%;
	height: 100%;
}

.pagination__item .icon {
	fill: currentColor;
	width: calc((16px + 16px + 1rem) / 3);
	height: calc((16px + 16px + 1rem) / 3);
}

.pagination__item--current {
	background-color: var(--color-greyish-brown);
	border: solid 1px var(--color-greyish-brown);
	color: var(--color-pinkish-white);
}

.pagination__arrow a {
	background-color: var(--color-pinkish-white);
	border: solid 1px var(--color-greyish-brown);
	box-sizing: border-box;
	color: var(--color-greyish-brown);
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
}

.pagination__arrow a:hover,
.pagination__arrow a:focus {
	background-color: var(--color-greyish-brown);
	border-color: var(--color-greyish-brown);
	color: var(--color-background);
}
