.page-intro {
	margin-top: var(--space-16--px);
	margin-bottom: var(--space-40--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.page-intro {
		margin-top: var(--space-24--px);
		margin-bottom: var(--space-48--px);
		width: calc(80% - calc(var(--gutter-20--px)));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.page-intro {
		width: calc(60% - calc(var(--gutter-20--px)));
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.page-intro {
		margin-top: var(--space-40--px);
		margin-bottom: var(--space-80--px);
		width: calc(60% - calc(var(--gutter-28--px)));
	}
}
