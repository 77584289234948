:root {
	/* #region Colors */
	--color-black: rgb(0 0 0);
	--color-greyish-brown: rgb(85 84 79);
	--color-off-white: rgb(251 247 238);
	--color-pinkish-white: rgb(255 252 247);
	/*  */
	--color-error: rgb(176 39 39);
	--color-valid: rgb(16 78 11);
	/*  */
	--color-alpha-foreground: rgb(0 0 0 / 30%);
	/*  */
	--color-foreground: rgb(0 0 0);
	--color-background: rgb(255 252 247);
	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "Bagoss Standard", sans-serif;
	--font-serif: "Courier", serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-1--px: 1px;
	--space-1--rem: 0.0625rem;
	--space-8--px: 8px;
	--space-8--rem: 0.5rem;
	--space-10--px: 10px;
	--space-10--rem: 0.625rem;
	--space-12--px: 12px;
	--space-12--rem: 0.75rem;
	--space-16--px: 16px;
	--space-16--rem: 1rem;
	--space-20--px: 20px;
	--space-20--rem: 1.25rem;
	--space-24--px: 24px;
	--space-24--rem: 1.5rem;
	--space-32--px: 32px;
	--space-32--rem: 2rem;
	--space-40--px: 40px;
	--space-40--rem: 2.5rem;
	--space-48--px: 48px;
	--space-48--rem: 3rem;
	--space-80--px: 80px;
	--space-80--rem: 5rem;
	/* #endregion Spacers */

	/* #region Gutters */
	--gutter-16--px: 16px;
	--gutter-16--rem: 1rem;
	--gutter-20--px: 20px;
	--gutter-20--rem: 1.25rem;
	--gutter-28--px: 28px;
	--gutter-28--rem: 1.75rem;
	/* #endregion Gutters */

	/* #region Z-Indices */
	--z-index-language-switch: 99;
	--z-index-modal-dialog: 100;
	/* #endregion Z-Indices */
}

@media (prefers-color-scheme: dark) {
	:root {
		/* #region Colors */
		--color-greyish-brown: rgb(150 150 141);
		--color-off-white: rgb(25 24 21);
		/*  */
		--color-foreground: rgb(255 252 247);
		--color-background: rgb(0 0 0);
		/*  */
		--color-error: rgb(255 96 96);
		--color-valid: rgb(59 172 49);
		/*  */
		--color-alpha-foreground: rgb(255 255 255 / 30%);
		/* #endregion Colors */
	}
}
