.artwork-intro-container {
	margin-top: var(--space-16--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.artwork-intro-container {
		display: grid;
		grid-column-gap: var(--gutter-20--px);
		grid-template-columns: minmax(0, 4fr) minmax(0, 6fr);
		margin-top: var(--space-24--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.artwork-intro-container {
		grid-column-gap: var(--gutter-28--px);
		margin-top: var(--space-40--px);
	}
}

/* #region Layout 40/60 */
.artwork-intro__40 h1,
.artwork-intro__40 p {
	margin: 0;
}

.artwork-intro__60 {
}
/* #endregion Layout 40/60 */

/* #region Thumbnail */
.artwork-intro__thumbnail {
	margin-top: var(--space-24--px);
	margin-bottom: var(--space-16--px);
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.artwork-intro__thumbnail {
		margin-top: 0;
	}
}
/* #endregion Thumbnail */

/* #region Viewport Link */
.artwork-intro__viewport-link {
	background: none;
	border: none;
	color: var(--color-greyish-brown);
	cursor: pointer;
	display: inline;
	padding: 0;
	text-decoration: underline 0.0625rem;
	transition: color 90ms linear;
}

.artwork-intro__viewport-link:hover {
	color: var(--color-foreground);
	text-decoration: none;
}
/* #endregion Viewport Link */

/* #region Info */
.artwork-intro__info {
	margin-top: var(--space-40--px);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.artwork-intro__info {
		display: flex;
		margin-top: var(--space-48--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.artwork-intro__info {
		margin-top: var(--space-80--px);
	}
}
/* #endregion Info */

/* #region Info - Specifications */
.artwork-intro__info__specifications {
	margin-bottom: var(--space-24--px);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.artwork-intro__info__specifications {
		margin-right: var(--space-16--px);
		margin-bottom: 0;
		width: 50%;
	}
}

.artwork-intro__info__specifications p {
	margin: 0;
}
/* #endregion Info - Specifications */

/* #region Info - Badges */
.artwork-intro__info__badges {
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.artwork-intro__info__badges {
		width: 50%;
	}
}
/* #endregion Info - Badges */
