/* #region List */
.artist-list {
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.artist-list {
		box-sizing: border-box;
	}
}
/* #endregion List */

/* #region List Item */
.artist-list__item {
	color: var(--color-greyish-brown);
	list-style-type: none;
	margin-bottom: var(--space-8--px);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.artist-list__item {
		margin-bottom: var(--space-12--px);
	}
}
/* #endregion List Item */
