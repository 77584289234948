@font-face {
	font-family: "Bagoss Standard";
	src: url("../fonts/BagossStandard-Regular.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Bagoss Standard";
	src: url("../fonts/BagossStandard-Medium.woff2") format("woff2");
	font-style: normal;
	font-weight: 500;
}
