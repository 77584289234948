/* from-xsmall,min-width-560px */
@media (min-width: 35rem) {
	.stories {
		display: grid;
		grid-column-gap: var(--gutter-20--px);
		grid-template-columns: minmax(0, 4fr) minmax(0, 6fr);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.stories {
		grid-column-gap: var(--gutter-28--px);
	}
}

/* #region Stories - Header */

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.stories-header {
		margin-bottom: var(--space-24--rem);
	}
}
/* #endregion Stories - Header */

/* #region Stories - List */
.stories-list {
}

.stories-list a {
	text-decoration: none;
}

.stories-list hr:last-child {
	display: none;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.stories-list-item {
		display: flex;
	}
}

.stories-list-item__title {
	margin: var(--space-16--rem) 0;
}

.stories-list-item__text {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	display: -webkit-box;
	overflow: hidden;
	max-height: calc(1.5rem * 3);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.stories-list-item__text {
		max-height: calc(1.75rem * 3);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.stories-list-item__text {
		-webkit-line-clamp: 4;
		max-height: calc(1.75rem * 4);
	}
}

.stories-list-item__image-container {
	flex-shrink: 0;
	margin-bottom: var(--space-16--px);
	overflow: hidden;
	width: 100%;
	max-width: 300px;
	max-height: 200px;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.stories-list-item__image-container {
		width: 10.625rem;
		max-width: none;
		height: 7.0625rem;
		max-height: none;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.stories-list-item__image-container {
		margin-right: var(--space-20--px);
		margin-bottom: 0;
		width: 9.375rem;
		height: 6.25rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.stories-list-item__image-container {
		width: 13.125rem;
		height: 8.75rem;
	}
}

.stories-list-item__image {
	width: 100%;
}

.stories-list-item__fallback {
	background-color: var(--color-greyish-brown);
	width: 100%;
	height: 200px;
}
/* #endregion Stories - List */
