.l-error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.l-error__container {
	margin: var(--space-10--px);
	max-width: 400px;
}

.l-error__header {
	display: flex;
	margin-bottom: var(--space-24--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.l-error__header {
		margin-bottom: var(--space-40--px);
	}
}

.l-error__header h1 {
	margin-top: 0;
	margin-left: var(--space-24--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.l-error__header h1 {
		margin-left: var(--space-40--px);
	}
}

.l-error__header__logo {
	height: fit-content;
}

.l-error__header__logo svg {
	width: 50px;
	height: 59px;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.l-error__header__logo svg {
		width: 56px;
		height: 66px;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-error__header__logo svg {
		width: 61px;
		height: 72px;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.l-error__header__logo svg {
		width: 76px;
		height: 90px;
	}
}

.l-error__main {
}
