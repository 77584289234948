.row {
	margin-top: var(--space-40--rem);
	margin-bottom: var(--space-40--rem);
	position: relative;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.row {
		margin-top: var(--space-48--rem);
		margin-bottom: var(--space-48--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	.row {
		margin-top: var(--space-80--rem);
		margin-bottom: var(--space-80--rem);
	}
}
