.artwork-grid {
	display: flex; /* old version (FLEX) */
	display: grid; /* modern override (GRID) */
	flex-direction: column; /* old version (FLEX) */
	grid-row-gap: var(--space-24--px);
	grid-column-gap: var(--space-16--px);
	grid-template-columns: repeat(2, 1fr);
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.artwork-grid {
		grid-row-gap: var(--space-48--px);
		grid-template-columns: repeat(3, 1fr);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.artwork-grid {
		grid-template-columns: repeat(4, 1fr);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.artwork-grid {
		grid-row-gap: var(--space-80--px);
		grid-column-gap: var(--space-20--px);
	}
}

.artwork-grid__item {
	list-style-type: none;
	max-width: 400px; /* old version (FLEX) */
}

/* from-small,tablet-p,min-width-768px to up-to-medium,max-width-1023px */
@media (min-width: 48rem) and (max-width: 63.9375rem) {
	.artwork-grid__item:nth-child(4) {
		display: none;
	}
}

/* modern override (GRID) */
@supports (display: grid) {
	.artwork-grid__item {
		max-width: unset;
	}
}

.artwork-grid__item__image {
	width: 100%;
}

.artwork-grid__item__fallback {
	background-color: var(--color-greyish-brown);
	width: 100%;
	height: 100%;
	min-height: 150px;
}
