/*
 * Form.
 */
.form {
	max-width: 448px;
}

/* Hide all for children except the success area */
/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.form[state="success"] > *:not([success-area]) {
	display: none;
}

/*
 * Row.
 */
.form__row {
	width: 100%;
}

.form__row:not(:last-child) {
	margin-bottom: var(--space-24--rem);
}

.form__row--disabled {
	opacity: 0.4;
}

/*
 from-wide,desktop,min-width-1280px
 */
@media (min-width: 80rem) {
	.form__row--button {
		margin-top: var(--space-40--rem);
	}
}

/*
 * Label.
 */
.form__label-container {
	display: block;
	margin-bottom: var(--space-8--rem);
	width: 100%;
}

.form__row--disabled .form__label {
	cursor: not-allowed;
}

.form__row--error .form__label {
	color: var(--color-error);
}

/*
 * Error.
 */
.form__error {
	color: var(--color-error);
	margin-top: 0.25rem;
}

/*
 * Fields.
 */
.form__input,
.form__select,
.form__textarea {
	background-color: transparent;
	border-top: none;
	border-right: none;
	border-bottom: var(--space-1--px) solid var(--color-greyish-brown);
	border-left: none;
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	padding: 0.1875rem 0;
	transition: border-color 96ms linear;
	width: 100%;
}

/* Fields — Select. */
.form__select-container {
	position: relative;
}

.form__select {
	appearance: none;
	color: var(--color-foreground);
	padding-right: var(--space-24--rem);
}

.form__select__arrow {
	pointer-events: none;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.form__select__arrow .icon {
	display: block;
	width: 1rem;
	height: 1rem;
}

/* Fields — Textarea. */
.form__textarea {
	resize: vertical;
	height: 9.375rem;
}

/* Fields — States. */
.form__input:focus,
.form__select:focus,
.form__textarea:focus {
	border-color: var(--color-foreground);
}

.form__input:disabled,
.form__select:disabled,
.form__textarea:disabled {
	cursor: not-allowed;
}

.form__row--error .form__input,
.form__row--error .form__select,
.form__row--error .form__textarea {
	border-color: var(--color-error);
}

/* Fields — Pseudo elements. */
.form__input::placeholder {
	color: var(--color-greyish-brown);
}

.form__select::placeholder {
	color: var(--color-greyish-brown);
}

.form__textarea::placeholder {
	color: var(--color-greyish-brown);
}

.form__select::-ms-expand {
	display: none;
}

/*
 * Checkboxes and radios.
 */
.form__checkbox:not(:last-child),
.form__radio:not(:last-child) {
	margin-bottom: var(--space-10--rem);
}

.form__checkbox__input,
.form__radio__input {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.form__checkbox__label,
.form__radio__label {
	color: var(--color-foreground);
	cursor: pointer;
	display: inline-block;
	margin-left: var(--space-32--rem);
	position: relative;
}

/* Checkboxes and radios — States. */
.form__checkbox__input:disabled + .form__checkbox__label,
.form__radio__input:disabled + .form__radio__label {
	cursor: not-allowed;
	opacity: 0.5;
}

/* Checkboxes and radios — Pseudo elements. */
.form__checkbox__label::before,
.form__radio__label::before,
.form__checkbox__label::after,
.form__radio__label::after {
	content: "";
	position: absolute;
}

.form__checkbox__label::before,
.form__radio__label::before {
	border: var(--space-1--rem) solid var(--color-greyish-brown);
	top: 0.5rem;
	left: -1.75rem;
	width: 0.5rem;
	height: 0.5rem;
}

/* from-wide,desktop */
.form__checkbox__label::after,
.form__radio__label::after {
	background-color: var(--color-foreground);
	opacity: 0;
	top: 0.5rem;
	left: calc(var(--space-32--rem) * -1 + 0.25rem);
	transition: opacity 96ms ease-out;
	width: 0.625rem;
	height: 0.625rem;
}

/* from-wide,desktop */
.form__checkbox__label::before,
.form__checkbox__label::after {
	border-radius: 2px;
}

.form__radio__label::before,
.form__radio__label::after {
	border-radius: 50%;
}

/* Checkboxes and radios — Pseudo elements — States. */
.form__checkbox__input:disabled + .form__checkbox__label::before,
.form__radio__input:disabled + .form__radio__label::before {
	cursor: not-allowed;
}

.form__checkbox__input:focus + .form__checkbox__label,
.form__radio__input:focus + .form__radio__label {
	color: var(--color-foreground);
	text-decoration: underline 0.0625rem;
}

.form__checkbox__input:checked + .form__checkbox__label::after,
.form__radio__input:checked + .form__radio__label::after {
	opacity: 1;
}

/* Date input */
.form__input--date::-webkit-calendar-picker-indicator {
	background: none;
	color: transparent;
}
