.artwork-overlay {
	background-color: var(--color-background);
	position: relative;
	height: 100%;
	min-height: 100vh;
}

.artwork-overlay__header {
	padding-top: var(--space-20--px);
	padding-right: var(--space-10--px);
	position: absolute;
	top: 0;
	right: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.artwork-overlay__header {
		padding-top: var(--space-16--px);
		padding-right: var(--space-16--px);
	}
}

.artwork-overlay__body {
	display: flex;
	padding: var(--space-80--px) var(--space-10--px);
	align-items: center;
	height: 100%;
	max-height: calc(100vh - calc(var(--space-80--px) * 2));
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.artwork-overlay__body {
		padding: var(--space-80--px);
	}
}

.artwork-overlay__inner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.artwork-overlay__inner__image {
	margin: 0 auto;
	max-height: calc(100vh - calc(var(--space-80--px) * 2));
}

/* #region Close Button */
.artwork-overlay__close-button {
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	padding: 0;
	justify-content: center;
	align-items: center;
}

.artwork-overlay__close-button .icon {
	display: block;
	fill: rgb(0 0 0);
	width: 1.375rem;
	height: 1.375rem;
}

@supports (color: var(--color-foreground)) {
	.artwork-overlay__close-button .icon {
		fill: var(--color-foreground);
	}
}
/* #endregion Close Button */
