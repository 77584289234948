.privacy-control {
}

/* #region Section */
.privacy-control__section:not(:last-child) {
	border-bottom: 1px solid var(--color-black);
	margin-bottom: var(--space-40--px);
	padding-bottom: var(--space-40--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.privacy-control__section:not(:last-child) {
		margin-bottom: var(--space-48--px);
		padding-bottom: var(--space-48--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.privacy-control__section:not(:last-child) {
		margin-bottom: var(--space-80--px);
		padding-bottom: var(--space-80--px);
	}
}

.privacy-control__section h2 {
	margin-bottom: var(--space-20--px);
}

.privacy-control__section h3 {
	margin-bottom: var(--space-12--px);
}
/* #endregion Section */

/* #region Column */
.privacy-control__columns:not(:last-child) {
	margin-bottom: var(--space-32--px);
}

.privacy-control__column:not(:first-child) {
	margin-top: var(--space-16--px);
}
/* #endregion Column */
