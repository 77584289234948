.site-header {
	box-sizing: border-box;
	display: flex;
	padding-top: var(--space-10--px);
	padding-right: var(--space-10--px);
	padding-bottom: var(--space-20--px);
	padding-left: var(--space-10--px);
	justify-content: space-between;
	align-items: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header {
		padding-top: var(--space-20--px);
		padding-bottom: var(--space-40--px);
		justify-content: flex-start;
		align-items: flex-start;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header {
		padding-top: var(--space-40--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-header {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		max-width: 87.5rem; /* 1400px */
	}
}

/* #region Logo */
.site-header__logo {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header__logo {
		width: 64px;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header__logo {
		width: 92px;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	.site-header__logo {
		width: calc(calc(100% - 72.5rem) / 2);
	}
}

.site-header__logo__link {
	display: block;
	width: fit-content;
}

.site-header__logo__link svg {
	display: block;
	fill: rgb(0 0 0);
	width: 2.3125rem;
	height: 2.75rem;
}

@supports (color: var(--color-foreground)) {
	.site-header__logo__link svg {
		fill: var(--color-foreground);
	}
}
/* #endregion Logo */

/* #region Main */
.site-header__main {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header__main {
		display: flex;
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 0;
	}
}
/* #endregion Main */

/* #region Navigation */
.site-header__navigation {
	display: none;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header__navigation {
		display: block;
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 0;
	}
}

.site-header__navigation__list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: space-between;
}

.site-header__navigation__link {
	color: var(--color-foreground);
	text-decoration: none;
}

.site-header__navigation__list__item--current .site-header__navigation__link,
.site-header__navigation__link:hover {
	color: var(--color-greyish-brown);
	text-decoration: underline 0.0625rem;
}
/* #endregion Navigation */

/* #region Language Switch */
.site-header__language-switch {
	display: none;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header__language-switch {
		border-left: var(--space-1--px) solid var(--color-greyish-brown);
		display: block;
		margin-left: var(--space-16--px);
		padding-left: var(--space-10--px);
		align-self: center;
		position: relative;
	}
}

.site-header__language-switch .icon {
	display: inline-block;
	width: 1rem;
	height: 1rem;
}

.site-header__language-switch__button {
	appearance: none;
	background: none;
	border: none;
	cursor: pointer;
}

.site-header__language-switch__button .icon-language-globe {
	fill: rgb(0 0 0);
	margin-right: 6px;
}

@supports (color: var(--color-foreground)) {
	.site-header__language-switch__button .icon-language-globe {
		fill: var(--color-foreground);
	}
}

.site-header__language-switch__button .icon-chevron-down {
	fill: rgb(0 0 0);
	transform: scale(1, 1);
	transition: transform 144ms ease-in-out;
}

@supports (color: var(--color-foreground)) {
	.site-header__language-switch__button .icon-chevron-down {
		fill: var(--color-foreground);
	}
}

.site-header__language-switch__button[aria-expanded="true"] .icon-chevron-down {
	transform: scale(1, -1);
}

.site-header__language-switch__menu {
	background-color: var(--color-background);
	border-top: var(--space-1--px) solid var(--color-greyish-brown);
	box-shadow: 0 4px 4px 0 var(--color-alpha-foreground);
	list-style: none;
	margin: var(--space-16--px) 0 0;
	opacity: 0;
	padding: var(--space-16--px);
	position: absolute;
	right: 0;
	transform: translate3d(0, calc(var(--space-16--px) * -1), 0);
	transition:
		opacity 96ms ease-out,
		transform 128ms ease-out;
	visibility: hidden;
	min-width: 175px;
}

.site-header__language-switch__menu.mr-menu--expanded,
html.no-js .site-header__language-switch__menu {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	visibility: visible;
	z-index: var(--z-index-language-switch);
}

.site-header__language-switch__menu__item:not(:last-child) {
	margin-bottom: var(--space-16--px);
}

.site-header__language-switch__link {
	color: var(--color-foreground);
	text-decoration: none;
}

.site-header__language-switch__link:hover {
	color: var(--color-greyish-brown);
	text-decoration: underline 0.0625rem;
}

.site-header__language-switch__menu__item--active .site-header__language-switch__link {
	color: var(--color-greyish-brown);
}
/* #endregion Language Switch */

/* #region Menu Button */
.site-header__menu-button {
	display: block;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-header__menu-button {
		display: none;
	}
}

.site-header__hamburger {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	color: currentColor;
	cursor: pointer;
	padding: 0;
	transform: translateY(2px);
	transition: color 96ms linear;
}

.site-header__hamburger .icon {
	display: block;
	fill: rgb(0 0 0);
	pointer-events: none;
	width: 1.875rem;
	height: 1.125rem;
}

@supports (color: var(--color-foreground)) {
	.site-header__hamburger .icon {
		fill: var(--color-foreground);
	}
}
/* #endregion Menu Button */
