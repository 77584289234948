@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}
}

/*
 * Give "display: none;" for elements with a "hidden" attribute specificity [0, 2, 0].
 * This will generally be higher than layout directives : "display: flex".
 * Because this is declared very early you can easily override it with ".foo[hidden]".
 */
[hidden][hidden] {
	display: none;
}

@supports (content-visibility: hidden) and (display: revert) {
	[hidden][hidden="until-found"] {
		content-visibility: hidden;
		display: revert;
	}
}

:root {
	color-scheme: light dark;
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	hyphens: auto;
	overflow-wrap: anywhere;
	position: relative;
	min-height: 100vh;
}

body.is-showing-modal-dialog {
	overflow: hidden;
}

textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

hr {
	border: none;
	border-bottom: var(--space-1--rem) solid currentColor;
	box-sizing: border-box;
	margin-top: var(--space-40--rem);
	margin-bottom: var(--space-40--rem);
	height: var(--space-1--rem);
}

/*
 from-small,tablet-p,min-width-768px
 */
@media (min-width: 48rem) {
	hr {
		margin-top: var(--space-48--rem);
		margin-bottom: var(--space-48--rem);
	}
}

/*
 from-wide,desktop,min-width-1280px
 */
@media (min-width: 80rem) {
	hr {
		margin-top: var(--space-80--rem);
		margin-bottom: var(--space-80--rem);
	}
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	margin: var(--space-32--rem) 0;
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	box-sizing: border-box;
	padding: var(--space-16--rem) var(--space-16--px);
	text-align: left;
}

:is(ol, ul):not([class]) {
	box-sizing: border-box;
	margin: 0 0 2rem 0;
	padding-left: var(--space-24--rem);
}

:is(ol, ul):not([class]):last-child {
	margin-bottom: 0;
}

li :is(ol, ul):not([class]) {
	margin-bottom: 0;
}

li:not([class]) {
	margin: 0;
	padding: 0 0 0 var(--space-8--px);
	position: relative;
}

ul li:not([class]) {
	list-style-type: "•   ";
}

a {
	color: var(--color-greyish-brown);
	display: inline;
	text-decoration: underline 0.0625rem;
	transition: color 90ms linear;
}

a:is(:active, :focus, :hover) {
	color: var(--color-foreground);
	text-decoration: none;
}

p a:not([class]) {
	line-height: inherit;
}

.wp-block-table {
	margin: var(--space-8--rem) 0; /* Remove margin from Figure tag set by Gutenberg */
}
