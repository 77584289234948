.primary-navigation {
	display: flex;
	justify-content: space-around;
}

.is-active,
.is-active a,
.primary-navigation__item--current,
.primary-navigation__item--current a {
	/* TODO : create real styles for navigation */
	color: red !important;
}
