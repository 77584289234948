.carousel {
	aspect-ratio: 3 / 2;
	display: flex;
	margin-bottom: var(--space-32--rem);
	position: relative;
}

.carousel__container {
	width: 100%;
	height: 100%;
}

.carousel__items {
	list-style: none;
	margin: 0;
	padding: 0;
	height: 100%;
}

.carousel__item {
	display: block;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition-duration: 640ms;
	transition-property: opacity;
	width: 100%;
	height: 100%;
}

.carousel__item[data-carousel-item-current] {
	opacity: 1;
}

.carousel__image {
	width: 100%;
	height: auto;
}

/* #region Button (Arrow) */
.carousel__button {
	appearance: none;
	background: none;
	background-color: var(--color-pinkish-white);
	border: 1px solid var(--color-greyish-brown);
	border-radius: 0;
	color: var(--color-black);
	cursor: pointer;
	display: flex;
	padding: 0;
	justify-content: center;
	align-items: center;
	position: absolute;
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
	width: 40px;
	height: 40px;
	z-index: 3;
}

.carousel__button:hover,
.carousel__button:focus {
	background-color: var(--color-greyish-brown);
	border-color: var(--color-greyish-brown);
	color: var(--color-background);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.carousel__button {
		width: 44px;
		height: 44px;
	}
}

.carousel__button .icon {
	fill: currentColor;
	pointer-events: none;
	width: calc((16px + 16px + 1rem) / 3);
	height: calc((16px + 16px + 1rem) / 3);
}

.carousel__button.carousel__button--previous {
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.carousel__button.carousel__button--next {
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}
/* #endregion Button (Arrow) */

/* #region Bottom Navigation */
.carousel__navigation {
	display: flex;
	list-style: none;
	margin: 0;
	padding-left: 0;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: calc(var(--space-32--rem) * -1);
	width: 100%;
}

.carousel__navigation__item {
	display: flex;
	margin-bottom: 0.375rem;
	align-items: center;
	height: 1.25rem;
}

.carousel__navigation__item:not(:last-child) {
	margin-right: var(--space-16--px);
}

.carousel__navigation__button {
	background: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	padding: 0;
	align-items: center;
	height: 100%;
}

.carousel__navigation__button__indicator {
	background-color: var(--color-greyish-brown);
	border: none;
	display: inline-block;
	pointer-events: none;
	width: 1.5625rem;
	height: 0.125rem;
}

.carousel__navigation__button[data-carousel-goto-current] .carousel__navigation__button__indicator {
	background-color: var(--color-foreground);
	height: 0.25rem;
}
/* #endregion Bottom Navigation */

/* #region NO JS */
.carousel-no-js {
	display: block;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
}

.carousel-no-js__items {
	list-style: none;
	margin: 0;
	padding: 0;
	white-space: nowrap;
}

.carousel-no-js__item {
	display: inline-block;
	scroll-snap-align: start;
	width: 100%;
}

.carousel-no-js__image {
	width: 100%;
	height: auto;
}
/* #endregion NO JS */
