.filters {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	html:not(.no-js) .filters {
		display: flex;
		flex-wrap: wrap;
	}
}

/* #region Item */
.filters__item {
	position: relative;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.filters__item:not(:last-child) {
		margin-right: var(--space-16--px);
		margin-bottom: var(--space-8--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.filters__item:not(:last-child) {
		margin-right: var(--space-24--px);
	}
}

html.no-js .filters__item:not(:first-child) {
	margin-top: var(--space-16--px);
}
/* #endregion Item */

/* #region Label */
.filters__label {
	appearance: none;
	background: none;
	border: none;
	color: var(--color-foreground);
	cursor: pointer;
	display: flex;
	padding: 0;
	align-items: center;
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.filters__label {
		margin-top: var(--space-16--px);
		margin-bottom: var(--space-16--px);
	}
}

.filters__label:hover,
.filters__item--active .filters__label {
	color: var(--color-greyish-brown);
}

.filters__label__arrow {
	margin-left: var(--space-8--px);
	width: var(--space-16--rem);
	height: var(--space-16--rem);
}

.filters__label__arrow .icon {
	display: block;
	fill: currentColor;
	transform: scale(1, 1);
	transition: transform 144ms ease-in-out;
	width: 100%;
	height: 100%;
}

.filters__label[aria-expanded="true"] .icon {
	transform: scale(1, -1);
}

html.no-js .filters__label {
	cursor: default;
}
/* #endregion Label */

/* #region List */
.filters__list {
	background-color: var(--color-background);
	border-top: var(--space-1--px) solid var(--color-greyish-brown);
	border-bottom: var(--space-1--px) solid var(--color-greyish-brown);
	list-style: none;
	margin: 0;
	opacity: 0;
	padding: var(--space-16--px) var(--space-8--px);
	transform: translate3d(0, calc(var(--space-16--px) * -1), 0);
	transition:
		opacity 96ms ease-out,
		transform 128ms ease-out;
	visibility: hidden;
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.filters__list {
		position: absolute;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.filters__list {
		border-top: var(--space-1--px) solid var(--color-greyish-brown);
		border-bottom: none;
		box-shadow: 0 4px 4px 0 var(--color-alpha-foreground);
		box-sizing: border-box;
		margin: var(--space-16--px) 0 0;
		padding: var(--space-16--px);
		position: absolute;
		left: 0;
		min-width: 250px;
		z-index: 1;
	}
}

.filters__list.mr-menu--expanded,
html.no-js .filters__list {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	visibility: visible;
}

html.no-js .filters__list {
	border-bottom: var(--space-1--px) solid var(--color-greyish-brown);
	box-shadow: none;
	position: relative;
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.filters__list.mr-menu--expanded {
		position: relative;
	}
}

.filters__list__item {
	display: flex;
	align-items: center;
}

.filters__list__item:not(:last-child) {
	margin-bottom: var(--space-16--px);
}

.filters__list__item::before {
	background: var(--color-background);
	border: var(--space-1--px) solid var(--color-greyish-brown);
	border-radius: 50%;
	content: "";
	display: block;
	margin-right: var(--space-16--px);
	width: 8px;
	height: 8px;
}

.filters__list__item--active::before {
	background: var(--color-greyish-brown);
}
/* #endregion List */

/* #region List Link */
.filters__list__link {
	color: var(--color-foreground);
	text-decoration: none;
}

.filters__list__link:hover,
.filters__list__item--active .filters__list__link {
	color: var(--color-greyish-brown);
	text-decoration: none;
}
/* #endregion List Link */
