.columns {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.columns {
		display: flex;
		justify-content: space-between;
	}
}

.columns--align-end {
	justify-content: flex-end;
}

/* #region Column */

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.column:first-child {
		margin-bottom: var(--space-24--px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.column--40 {
		width: calc(40% - calc(var(--gutter-20--px) / 2));
	}

	.column--60 {
		width: calc(60% - calc(var(--gutter-20--px) / 2));
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.column--40 {
		width: calc(40% - calc(var(--gutter-28--px) / 2));
	}

	.column--60 {
		width: calc(60% - calc(var(--gutter-28--px) / 2));
	}
}
/* #endregion Column */

/* #region Image */
.column__image img {
	width: 100%;
}
/* #endregion Image */

/* #region Text Columns */

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.text-columns {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.text-columns--align-end {
		justify-content: flex-end;
	}
}

/* up-to-medium,max-width-1023px */
@media (max-width: 63.9375rem) {
	.text-column {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}

	.text-column:not(:last-child) {
		margin-bottom: var(--space-24--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.text-column {
		width: calc(50% - calc(var(--gutter-20--px) / 2));
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.text-column {
		width: calc(50% - calc(var(--gutter-28--px) / 2));
	}
}
/* #endregion Child Column 50% */
