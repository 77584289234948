.site-footer {
	background-color: var(--color-off-white);
	padding-top: var(--space-40--px);
	padding-bottom: var(--space-40--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer {
		padding-top: var(--space-48--px);
		padding-bottom: var(--space-48--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer {
		padding-top: var(--space-80--px);
		padding-bottom: var(--space-80--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-footer__meta-container {
		display: flex;
	}
}
/* #region Navigation List / Meta List */
.site-footer__navigation-list,
.site-footer__meta-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.site-footer__meta-list {
	display: flex;
}

.site-footer__meta-list__item:not(:last-child) {
	margin-right: var(--space-24--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.site-footer__meta-list__item:not(:last-child) {
		margin-right: var(--space-16--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-footer__meta-list__item:not(:last-child) {
		margin-right: var(--space-8--px);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	.site-footer__meta-list__item:not(:last-child) {
		margin-right: var(--space-16--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__meta-list__item:not(:last-child) {
		margin-right: var(--space-24--px);
	}
}

.site-footer__navigation-list__item__link[aria-current="page"],
.site-footer__meta-list__item__link[aria-current="page"] {
	color: var(--color-foreground);
	text-decoration: none;
}
/* #endregion Navigation List / Meta List */

/* #region Meta Copyright */
.site-footer__meta__copyright {
	margin-bottom: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-footer__meta__copyright {
		margin-right: var(--space-8--px);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	.site-footer__meta__copyright {
		margin-right: var(--space-16--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__meta__copyright {
		margin-right: var(--space-24--px);
	}
}
/* #endregion Meta Copyright */

/* #region FIT (Flanders Investment Trade) */
.site-footer__fit p {
	margin-bottom: var(--space-24--px);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__fit p {
		margin-bottom: var(--space-40--px);
	}
}

.site-footer__fit__logo {
	max-width: 232px;
}

@media (prefers-color-scheme: dark) {
	.site-footer__fit__logo img {
		filter: invert(1);
	}
}
/* #endregion FIT (Flanders Investment Trade) */
