.button {
	appearance: none;
	background-color: transparent;
	border: var(--space-1--px) solid var(--color-foreground);
	color: var(--color-foreground);
	cursor: pointer;
	display: inline-block;
	margin: 0;
	padding: 7px var(--space-16--px);
	text-decoration: none;
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
}

.button:not(:disabled):hover,
.button:focus {
	background-color: var(--color-greyish-brown);
	border-color: var(--color-greyish-brown);
	color: var(--color-background);
}

.button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}
