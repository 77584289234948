.information-request {
}

.information-request__status-available {
	color: var(--color-valid);
}

.information-request__status-unavailable {
	color: var(--color-error);
}

.information-request__button {
	margin-top: var(--space-24--px);
	margin-bottom: var(--space-24--px);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.information-request__button {
		margin-top: var(--space-40--px);
		margin-bottom: var(--space-40--px);
	}
}
