.stories-intro {
	padding-top: var(--space-16--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.stories-intro {
		padding-top: var(--space-24--px);
		width: 80%;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.stories-intro {
		width: 60%;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.stories-intro {
		padding-top: var(--space-40--px);
	}
}

.stories-intro__title {
	margin-bottom: var(--space-16--px);
}

@media (min-width: 48rem) {
	.stories-intro__title {
		margin-bottom: var(--space-24--px);
	}
}
