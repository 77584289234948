.artist-intro {
	padding-top: var(--space-16--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.artist-intro {
		display: flex;
		flex-flow: row;
		padding-top: var(--space-24--px);
		justify-content: space-between;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.artist-intro {
		padding-top: var(--space-40--px);
	}
}

.artist-intro__40 {
}

.artist-intro__60 {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.artist-intro__40 {
		width: calc(40% - calc(var(--gutter-20--px) / 2));
	}

	.artist-intro__60 {
		width: calc(60% - calc(var(--gutter-20--px) / 2));
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.artist-intro__40 {
		width: calc(40% - calc(var(--gutter-28--px) / 2));
	}

	.artist-intro__60 {
		width: calc(60% - calc(var(--gutter-28--px) / 2));
	}
}

.artist-intro__title {
	padding-bottom: var(--space-24--px);
}

.artist-intro__job {
	margin: 0;
}
