* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-sans);
	font-size: 0.875rem;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	body {
		font-size: 1rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	body {
		font-size: 1.125rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-sans);
}

h1 {
	margin-top: var(--space-40--rem);
	margin-bottom: var(--space-40--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	h1 {
		margin-top: var(--space-48--rem);
		margin-bottom: var(--space-48--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	h1 {
		margin-top: var(--space-80--rem);
		margin-bottom: var(--space-80--rem);
	}
}

h2,
h3,
h4,
h5,
h6 {
	margin-top: var(--space-24--rem);
	margin-bottom: var(--space-24--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: var(--space-48--rem);
		margin-bottom: var(--space-48--rem);
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: var(--space-40--rem);
		margin-bottom: var(--space-40--rem);
	}
}

:is(h1, h2, h3, h4, h5, h6, p):first-child {
	margin-top: 0;
}

:is(h1, h2, h3, h4, h5, h6, p):last-child {
	margin-bottom: 0;
}

:is(h1, h2, h3, h4, h5, h6):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

h5,
h6 {
	text-transform: uppercase;
}

/* #region Type-a */
/**
 * Names used in Sketch design files:
 * - Mobile/Type-A
 * - TabletP/Type-A
 * - TabletL/Type-A
 * - Desktop/Type-A
 */
.type-a {
	font-family: var(--font-sans);
	font-size: 1.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.25;
	text-underline-offset: 0.5rem;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.type-a {
		font-size: 0.875rem;
		line-height: 1.5;
		text-underline-offset: 0.25rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.type-a {
		font-size: 1rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	p,
	.type-a {
		font-size: 1.125rem;
	}
}
/* #endregion Type-a */

/* #region Type-b */
/**
 * Names used in Sketch design files:
 * - Mobile/Type-B
 * - TabletP/Type-B
 * - TabletL/Type-B
 * - Desktop/Type-B
 */
h1,
.type-b {
	font-family: var(--font-sans);
	font-size: 1.375rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
	text-underline-offset: 0.375rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	h1,
	.type-b {
		font-size: 1.5rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	h1,
	.type-b {
		font-size: 1.875rem;
	}
}
/* #endregion Type-b */

/* #region Type-c */
/**
 * Names used in Sketch design files:
 * - Mobile/Type-C
 * - TabletL/Type-C
 * - Desktop/Type-C
 */
.type-c {
	font-family: var(--font-sans);
	font-size: 1.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.25;
	text-underline-offset: 0.5rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.type-c {
		font-size: 2rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	.type-c {
		font-size: 2.625rem;
		line-height: 1.19;
	}
}
/* #endregion Type-c */

/* #region Type-d */
/**
 * Names used in Sketch design files:
 * - Mobile/Type-D
 * - TabletP/Type-D
 * - TabletL/Type-D
 * - Desktop/Type-D
 */
h2,
blockquote > p,
.type-d {
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.2;
	text-underline-offset: 0.375rem;
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	h2,
	blockquote,
	.type-d {
		font-size: 1.5rem;
		line-height: 1.33;
	}
}
/* #endregion Type-d */

/* #region Type-e */
/**
 * Names used in Sketch design files:
 * - Mobile/Type-E
 * - TabletP/Type-E
 * - TabletL/Type-E
 * - Desktop/Type-E
 */
h3,
.type-e {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 500;
	letter-spacing: normal;
	line-height: 1.5;
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	h3,
	.type-e {
		font-size: 1.125rem;
	}
}
/* #endregion Type-e */

/* #region Type-f */
/**
 * Names used in Sketch design files:
 * - Mobile/Type-F
 * - TabletP/Type-F
 * - TabletL/Type-F
 * - Desktop/Type-F
 */
h4,
th,
.type-f {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.03125rem;
	line-height: 1.5;
	text-transform: uppercase;
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	h4,
	th,
	.type-f {
		font-size: 0.875rem;
	}
}
/* #endregion Type-f */

/* #region Type-g */
/**
 * Names used in Sketch design files:
 * - Mobile/Type-G, Mobile/Type-J
 * - TabletP/Type-G, TabletP/Type-J
 * - TabletL/Type-G, TabletL/Type-J
 * - Desktop/Type-G, Desktop/Type-J
 */
p,
ul,
ol,
.type-g {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
	text-underline-offset: 0.25rem;
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	p,
	ul,
	ol,
	.type-g {
		font-size: 1.125rem;
		text-underline-offset: 0.3125rem;
	}
}
/* #endregion Type-g */

/* #region Type-h */
/**
 * Names used in Sketch design files:
 * - Mobile/Type-H, Mobile/Type-I
 * - TabletP/Type-H, TabletP/Type-I
 * - TabletL/Type-H, TabletL/Type-I
 * - Desktop/Type-H, Desktop/Type-I
 */
h5,
h6,
td,
blockquote > cite,
.type-h {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	h5,
	h6,
	td,
	blockquote > cite,
	.type-h {
		font-size: 0.875rem;
	}
}
/* #endregion Type-h */

/* #region Type-i */
/**
 * Names used in Sketch design files:
 * - Mobile/Type-K
 * - TabletP/Type-K
 * - TabletL/Type-K
 * - Desktop/Type-K
 */
code,
.type-i {
	font-family: var(--font-serif);
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	code,
	.type-i {
		font-size: 0.875rem;
	}
}
/* #endregion Type-i */

/* #region blockquote */
blockquote {
	margin: var(--space-32--rem) 0;
}

blockquote > p {
	margin-bottom: 0.75rem;
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	blockquote > p {
		margin-bottom: var(--space-16--rem);
	}
}

blockquote > cite {
	display: block;
}
/* #endregion blockquote */

i,
em {
	font-style: italic;
}

strong,
b {
	font-weight: 500;
}
