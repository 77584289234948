.giftcard {
}

.giftcard__embed {
	max-height: 320px;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.giftcard__embed {
		max-height: 420px;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.giftcard__embed {
		max-height: 520px;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.giftcard__embed {
		max-height: 620px;
	}
}
