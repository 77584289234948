.wrapper {
	box-sizing: border-box;
	padding-right: 10px;
	padding-left: 10px;
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.wrapper {
		padding-right: 74px;
		padding-left: 74px;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.wrapper {
		padding-right: 102px;
		padding-left: 102px;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	.wrapper {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 72.5rem; /* 1160px */
	}
}
