.clients-grid {
	display: grid;
	grid-row-gap: var(--gutter-16--px);
	grid-column-gap: var(--gutter-16--px);
	grid-template-columns: repeat(2, 1fr);
	margin: 0;
	padding: 0;
}

/* from-xsmall,min-width-560px */
@media (min-width: 35rem) {
	.clients-grid {
		grid-template-columns: repeat(3, 1fr);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.clients-grid {
		grid-template-columns: repeat(5, 1fr);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.clients-grid {
		grid-row-gap: var(--gutter-20--px);
		grid-column-gap: var(--gutter-28--px);
	}
}

/* #region Grid - Item */
.clients-grid__item {
	display: flex;
	justify-content: center;
	align-items: center;
}
/* #endregion Grid - Item */

/* #region Grid - Item Image */
.clients-grid__item__image {
	width: 100%;
	height: auto;
}

@media (prefers-color-scheme: dark) {
	.clients-grid__item__image {
		filter: invert(1);
	}
}
/* #endregion Grid - Item Image */
